import React, { Fragment, useState } from 'react'
import { Grid, TextField, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { useGetAnEmployeeRatingQuery, useRatingQAQuery } from '../../../features/review/reviewApi'
import SingleRatingQA from './SingleRatingQA';

ReadOnlyEmployeReview.propTypes = {
    id: PropTypes.number,
};

export default function ReadOnlyEmployeReview({ id, setFieldValue }) {
    const { data, isLoading, isError, error } = useRatingQAQuery();
    const { data: old } = useGetAnEmployeeRatingQuery(id);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);


    let content = null;
    if (isLoading) {
        content = <div>Loading...</div>
    }
    if (!isLoading && isError) {
        content = <p>{error}</p>
    }
    if (!isLoading && !isError && data?.data?.length === 0) {
        content = <p>No Found data</p>
    }

    if (!isLoading && !isError && data?.data?.length > 0) {
        content = data?.data?.map((row, key) => <SingleRatingQA key={key} row={row} id={id} />)
    }

    const handleClickOpen = (field, content, question, editable) => {
        setCurrentField(field);
        setDialogTitle(question);
        setDialogContent(content);
        setOpenDialog(true);
        setIsEditable(editable);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {
        setFieldValue(currentField, dialogContent)
        setOpenDialog(false);
    };

    return (
        <Grid item xs={12} lg={9}>
            <Typography variant="h5" mb={3} color="initial">
                Self Appraisals
            </Typography>
            {old?.data?.employeeSelfApprasalQAs?.map((data) =>
                <Fragment key={data?.selfAppraisalQuestionId}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            label={`${data?.selfAppraisalQuestionId}. ${data?.question}`}
                            value={data?.answer}
                            disabled
                            sx={{ mb: 2 }}
                            variant="outlined"
                        />
                        <Box onClick={() => handleClickOpen(`selfApprasal-${data?.selfAppraisalQuestionId}`, data?.answer, data?.question, false)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                        </Box>
                    </Box>
                </Fragment>
            )}
            {content}
            <Grid container spacing={3} my={5}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'> Areas Identified for Training & Development/ Additional Comments </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            value={old?.data?.areasIdentifiedforTrainingDevelopment}
                            fullWidth
                            multiline
                            disabled
                            rows={2}
                        />
                        <Box onClick={() => handleClickOpen('areasIdentifiedforTrainingDevelopment', old?.data?.areasIdentifiedforTrainingDevelopment, 'Areas Identified for Training and Development', false)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        value={dialogContent}
                        onChange={(e) => setDialogContent(e.target.value)}
                        variant="outlined"
                        disabled={!isEditable}
                        InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                    {isEditable && <Button onClick={() => handleSave(setFieldValue)} color="primary">Save</Button>}
                </DialogActions>
            </Dialog>
        </Grid>
    )
}


